#tasks {
  width: 30rem;
  max-width: 80%;
  margin: 4rem auto;
}

#task-form {
  width: 30rem;
  max-width: 80%;
  margin: 4rem auto;
  padding: 3rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
