.form-control {
  margin: 0.5rem 0;
}

.form-control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.form-control input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
  line-height: 1.5rem;
  padding: 0 0.25rem;
}

.form-control input:focus {
  outline: none;
  background: #c8e1e4;
  border-color: #00358b;
}

/*Ссылка на элемент у которого есть оба стиля, а также в нем инпут*/
.form-control.invalid input {
  border-color: red;
  background: rgb(243, 157, 157);
}

.form-control.invalid label {
  color: red;
}